<template>
  <CContainer>
    <div class="loginSection">
      <CCardGroup>
        <CCard
          color="primary"
          text-color="white"
          class="text-center py-5 mb-0 loginSection-item d-md-down-none"
        >
          <h2>MyTruck Cloud</h2>
          <p>{{ version }}</p>
        </CCard>
        <CCard class="loginSection-item">
          <CCardBody>
            <div class="cardLogin-img">
              <img :src="src" height="50" />
            </div>
            <CForm>
              <CInput
                v-if="showLegacyButton"
                v-bind:placeholder="$t('username')"
                :autocomplete="$t('userName_email')"
                @keyup.enter.native="login"
                @input="checkUser"
                required
                :is-valid="usernameSt"
                v-model="username"
              >
                <template #prepend-content>
                  <CIcon name="cil-user" />
                </template>
              </CInput>
              <CInput
                v-if="showLegacyButton"
                v-bind:placeholder="$t('password')"
                type="password"
                :autocomplete="$t('current_password')"
                required
                @keyup.enter.native="login"
                @input="checkPassword"
                :is-valid="passwordSt"
                v-model="password"
              >
                <template #prepend-content>
                  <CIcon name="cil-lock-locked" />
                </template>
              </CInput>
              <CRow>
                <CCol col="12" class="mt-3">
                  <CButton
                    @click="login"
                    :disabled="inLogin"
                    v-if="showLegacyButton"
                    color="primary"
                    class="px-5 py-2 btn-login"
                    >{{ $t("login") }}</CButton
                  >
                </CCol>
                <CCol col="12" class="mt-3">
                  <CButton
                    @click="showCiamLogin"
                    :disabled="inLogin"
                    color="primary"
                    class="px-5 py-2 btn-login"
                    >{{ $t("ciam_login") }}</CButton
                  >
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12" class="text-center">
                  <div
                    style="
                      margin: 0px auto;
                      text-align: center;
                      margin-top: 20px;
                      color: red;
                    "
                  >
                    &nbsp;
                    <p>{{ message }}</p>
                  </div>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCardGroup>
    </div>
  </CContainer>
</template>

<script>
import AuthService from "@/services/api/auth";
import LanguageService from "@/services/api/languages"
import { bus } from "@/main.js";
import types from "./../ThemeJs/theme";
import Vue from "vue";

let src;
let buttonType;
types["types"].theme.map((tema, i) => {
  if (
    (tema.name == "holcim" && tema.state) ||
    (tema.name == "laFarge" && tema.state) ||
    (tema.name == "laFargeHolcim" && tema.state) ||
    (tema.name == "holcimRedAlternative" && tema.state)
  ) {
    src = tema.logoLargeLogin;
    console.log(tema.logoLargeLogin);
  }
  if (
    (tema.name == "holcimRed" && tema.state) ||
    (tema.name == "laFargeGreen" && tema.state) ||
    (tema.name == "laFargeHolcimBlue" && tema.state)
  ) {
    buttonType = "secondary";
    src = tema.logoLargeLogin;
    console.log(tema.logoLargeLogin);
  }
});

export default {
  name: "Login",
  data() {
    return {
      inheritAttrs: false,
      username: "",
      password: "",
      usernameSt: null,
      passwordSt: null,
      loading: false,
      inLogin: false,
      message: "",
      themesVue: types["types"].theme,
      showLegacyButton: false,
      buttonType: "primary",
      src: src,
      mode: Vue.prototype.$mode,
      version: Vue.prototype.$version,
    };
  },
  mounted() {
    localStorage.removeItem("user");
    localStorage.removeItem("userRol");
    var vm = this;
    setTimeout(() => {
      vm.loading = false;
    }, 5000);
    
    this.setTypesThemeState();
    this.setUpGigya();
  },
  created() {
      let userCountry = localStorage.getItem('selectedLanguage') || 'en'
        this.loadLegacy()
      // localStorage.removeItem('i18n')
      LanguageService.getTranslations().then((language) => {
        let countryLanguage = language.data;
        let translations2 = countryLanguage.find(e => e.locale == userCountry)
        this.$i18n.i18next.changeLanguage(userCountry)
        countryLanguage.forEach(item => {
          this.$i18n.i18next.addResourceBundle(item.locale, "translation", item.translations);
        })  
        let translations = {}
        countryLanguage.forEach(item => {
          translations[item.locale] = item.translations;
        })
        let idiomas = {
          language: userCountry,
          translations: translations
        }
        
        localStorage.setItem('i18n', JSON.stringify(idiomas))
      })
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.i18next.changeLanguage(lang);
    },

    setTypesThemeState() {
      var e;
      var themes = localStorage.theme;
      if (themes.length == 0 || themes == null) {
        e = "laFargeHolcimblueAlternative";
      } else {
        e = themes;
      }

      this.themesVue.map((tema, i) => {
        if (tema.name == e) {
          tema.state = true;
        } else {
          tema.state = false;
        }
      });
      this.themesVue.map((tema, i) => {
        if (
          (e == "holcim" && tema.state) ||
          (e == "laFarge" && tema.state) ||
          (e == "laFargeHolcim" && tema.state) ||
          (e == "holcimRedAlternative" && tema.state) ||
          (e == "laFargeGreenAlternative" && tema.state) ||
          (e == "laFargeHolcimblueAlternative" && tema.state)
        ) {
          console.log(tema.logoLargeLogin);
          this.buttonType = "primary";
          this.src = tema.logoLargeLogin;
          bus.$emit("tipoBotton", this.buttonType);
          bus.$emit("tema", tema);
        }
        if (
          (e == "holcimRed" && tema.state) ||
          (e == "laFargeGreen" && tema.state) ||
          (e == "laFargeHolcimBlue" && tema.state)
        ) {
          console.log(tema.logoLargeLogin);
          this.src = tema.logoLargeLogin;
          this.buttonType = "secondary";
          bus.$emit("tipoBotton", this.buttonType);
          bus.$emit("tema", tema);
        }
      });
      types.selectTheme(types.types.theme);
    },
    setUserTheme(theme) {
      let e = theme;
      if (e == undefined) e = "laFargeGreenAlternative";

      types["types"].theme.map((tema) => {
        if (tema.name == e) {
          tema.state = true;
        } else {
          tema.state = false;
        }
      });
      localStorage.theme = e;

      types.selectTheme(types.types.theme);
      types["types"].theme.map((tema, i) => {
        if (
          e == "holcimRedAlternative" ||
          e == "laFargeGreenAlternative" ||
          e == "laFargeHolcimblueAlternative"
        ) {
          this.buttonType = "primary";
          bus.$emit("tipoBotton", this.buttonType);
          bus.$emit("tema", tema);
        }
        if (
          e == "holcimRed" ||
          e == "laFargeGreen" ||
          e == "laFargeHolcimBlue"
        ) {
          this.buttonType = "secondary";
          bus.$emit("tipoBotton", this.buttonType);
          bus.$emit("tema", tema);
        }
      });
      this.types = types.types.theme;
    },
    setLocalStorage(user) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userRol", JSON.stringify(user.rol));
      localStorage.setItem("userCountry", user.country ? JSON.stringify(user.country) : "");
      if (user.carrierInfo) {
        localStorage.setItem("carrierInfo", JSON.stringify(user.carrierInfo))
      } else {
        localStorage.removeItem("carrierInfo")
      }
      if(user.tpp && user.tpp.length > 0) {
        localStorage.setItem("tpps", JSON.stringify(user.tpp))
      } else {
        localStorage.removeItem("tpps")
      }
    },
    login() {
      var vm = this;
      let go = true;

      if (vm.username == "") {
        go = false;
        vm.usernameSt = false;
      } else vm.usernameSt = true;

      if (vm.password == "") {
        go = false;
        vm.passwordSt = false;
      } else vm.passwordSt = true;

      vm.inLogin = true;

      if (!go) vm.inLogin = false;

      if (go) {
        vm.inLogin = true;
        this.loading = true;

        AuthService.login(vm.username, vm.password, vm.plantsOptionSelected)
          .then((response) => {
            vm.inLogin = false;
            this.loading = false;

            var code = response.data.ret;

            if (code == -1) {
              vm.message = this.$i18n.t("userNamePasswordIncorrect");
            } else {
              //TODO RMX verificar objetos country que guarde en desktop offline la misma info que cloud
              var user = response.data;
              console.log("user %O", user);
              this.setLocalStorage(user);
              this.setUserTheme(user.theme);
              
              this.loading = true;
              location.href = "#/dashboard?t" + new Date().getMilliseconds();
            }
          })
          .catch((e) => {
            console.log("ERROR CATCH: %O", e);
            vm.inLogin = false;
            this.loading = false;
            vm.message = e;
            console.log(this.$i18n.t("login_error") + e);
          });
      }
    },
    showCiamLogin() {
      this.waitForGigyaLoad(() => {
        gigya.accounts.showScreenSet({
          screenSet: 'mtruck-RegistrationLogin',
          startScreen: 'LoginMyTruck_EXT',
          deviceType: 'auto'
        })
      });
    },
    checkUser() {
      var vm = this;
      if (vm.username == "") vm.usernameSt = false;
      else vm.usernameSt = true;
    },

    checkPassword() {
      var vm = this;

      if (vm.password == "") vm.passwordSt = false;
      else vm.passwordSt = true;
    },
    loadLegacy() {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", "/legacy/value", true);
        xhr.onload = (e) => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              if (xhr.responseText == "OFF") {
                this.showLegacyButton = false;
              } else{
                this.showLegacyButton = true;
              }
            }
          }
        };
        xhr.send(null);
    },
    setLanguageUser(text, user) {
      for (const key in text) {
        // eslint-disable-next-line no-prototype-builtins
        if (user.text.hasOwnProperty(key)) {
          const element = user.text[key];
          this.$i18n.i18next.addResourceBundle(key, "translation", element);
        }
      }
    },
    waitForGigyaLoad(callback) {
      if (typeof gigya !== 'undefined' && gigya.accounts) {
        callback();
      } else {
        setTimeout(() => this.waitForGigyaLoad(callback), 200); 
      }
    },
    setUpGigya() {
      this.waitForGigyaLoad(() => { 
        gigya.accounts.addEventHandlers({
          onLogin: async (response) => {
            this.inLogin = true;
            this.loading = true;
            
            try {
              const { id_token: token } = await this.getCiamUserJWT();
              const { data: user } = await AuthService.ciamAuthentication(token);
              
              this.inLogin = false;
              this.loading = false;
  
              console.log("user %O", user);
              this.setLocalStorage(user);
              this.setUserTheme(user.theme);
  
              this.loading = true;
              location.href = "#/dashboard?t" + new Date().getMilliseconds();
            } catch (error) {
              this.inLogin = false;
              this.loading = false;
              this.message = error.message;
              console.log(this.$i18n.t("login_error") + error.message);
            }
          }
        });
      })
    },
    getCiamUserJWT() {
      return new Promise((resolve, reject) => {
        gigya.accounts.getJWT({
          fields: "email,UID", 
          "callback": function(response) {
            if(response.status === 'OK') {
              resolve(response);
            } else {
              reject(response.errorMessage);
            }
          },
          "expiration": 3600
        });
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.loginSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginSection-item {
  min-width: 300px;
}
.cardLogin-img {
  text-align: center;
  margin-bottom: 2em;
}
.btn-login {
  width: 100%;
}
</style>